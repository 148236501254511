<template>
  <div class="page-box">
    <headBar ref="headerHeight" title="超鹿月卡" background="#20252E" color="#fff" left-arrow @click-left="newAppBack">
      <template slot="right">
        <img @click="isShareShow = true" class="share-icon" src="https://img.chaolu.com.cn/ACT/double11-2022/index/share-icon.png" alt="">
      </template>
    </headBar>
    <app-page id="box" class="page-img">
      <van-sticky :offset-top="headerHeight">
        <div class="city-nav-box row-start-center">
          <div @click="cityChange(item)" class="row-center-center" :class="{ active: item.cityId === currentCityId }" v-for="(item, index) in cityList" :key="index">
            {{ item.areaName }}卡
          </div>
        </div>
      </van-sticky>

      <div class="page-h row-center-start" v-if="selectItem.equityCardItemId">
        <div class="col-between-start" :style="`background-image: url(${cardInfo.img})`">
          <p class="col-start-start">
            <span>{{ cardInfo.equityCardName }}</span>
            <!-- <span v-if="selectItem.isPerOnlyVenue && venueName">{{ venueName }}</span>-->
            <span>{{ cityName }}</span>
          </p>
          <div class="row-between-end">
            <div class="row-start-end level-describe">
              <p v-show="selectItem.payType !== 'COMMON'">购买立即生效并激活</p>
              <p v-show="selectItem.payType === 'COMMON'">进馆自动生效</p>
              <p v-show="selectItem.payType === 'COMMON'">可随时手动激活</p>
            </div>
            <div v-if="selectItem.isPerOnlyVenue" class="row-start-center select-venue-btn">
              <span v-if="distance">门店距你{{ distance }}</span>
              <div @click="showChooseVenue(true)" class="row-center-center">切换购买门店</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="finished" class="page-h" style="padding: 0 4.266vw;box-sizing: border-box">
        <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/empty_bg.png" alt="" style="width: 100%;height: 71.2vw;">
      </div>

      <div class="card-box" v-show="selectItem.equityCardItemId">
        <div class="card-title row-between-center">
          <p class="f32 fw6">选择套餐</p>
          <p v-if="selectItem.payType === 'ALI_PRE_ENJOY'" @click="isZhimaShow = true" class="f22 p1">芝麻先享活动规则 <van-icon name="info-o" /></p>
          <p v-else-if="selectItem.firstPrice" class="f22 p1">仅限首单新人，不可转赠</p>
          <p v-else-if="selectItem.buyKnow" class="f22 p1 ellipsis" style="max-width: 60%;">{{ selectItem.buyKnow }}</p>

        </div>
        <div :class="['new-card-list', 'flex', 'flex-wrap', isExpand ? 'expand' : '']">
          <div @click="selectCard(item)" class="card-item flex-auto col-start-center" :class="{ active: item.equityCardItemId === selectItem.equityCardItemId }" v-for="(item, index) in cardInfo.items" :key="index">

            <p class="ellipsis card-name f24 fw6">{{ item.equityCardItemName }}</p>
            <div class="price f64 BebasNeueBold" v-if="!(giveUpFirstBuy && item.equityCardItemId === selectItem.equityCardItemId) && item.firstPrice">
              <span class="f28">¥ </span>{{ item.firstPrice | priceFormat }}
            </div>
            <div class="price f64 BebasNeueBold" v-else-if="item.salePrice">
              <span class="f28">¥ </span>{{ item.salePrice | priceFormat }}
            </div>
            <!-- 券后标签 -->
            <p v-if="item.discountPrice" class="row-center-center couponAfterPrice">
              券后¥{{ item.discountPrice | priceFormat }}
            </p>
            <div v-if="item.originalPrice" class="ori-price f20">¥{{ (item.payType !== 'COMMON' && item.firstPrice) ? (item.salePrice) : (item.originalPrice) | priceFormat }}</div>

            <div v-if="item.equityCardItemLightspot" class="item-tips f20 ellipsis">{{ item.equityCardItemLightspot }}</div>
            <!-- 芝麻先享 标签 -->
            <p v-if="item.payType === 'ALI_PRE_ENJOY'" class="tag f18 fw6 blue row-center-center">支付宝</p>
            <!-- 专享价 标签 -->
            <p v-else-if="!(giveUpFirstBuy && item.equityCardItemId === selectItem.equityCardItemId) && item.firstPrice" class="tag f18 fw6 row-center-center">新客专享价</p>
            <!-- 普通 标签 -->
            <span v-else-if="item.activeLabel" class="tag f18 fw6 row-center-center">{{ item.activeLabel }}</span>

          </div>

        </div>
        <div @click="isExpand = !isExpand" class="f22 expand-button row-center-center">{{ isExpand ? '收起' : '展开查看更多' }} <van-icon :class="[isExpand ? 'rotate' : '']" name="play" /></div>

        <!-- <div class="card-tips f22">{{  }}</div> -->

        <div class="gift-detail" v-if="selectItem.equityCardEquityVOList && selectItem.equityCardEquityVOList.length">
          <div class="gift-detail-bottom">
            <h4>权益列表</h4>
            <div class="gift-detail-item" v-for="(item, index) in selectItem.equityCardEquityVOList" :key="index">
              <img @click="jumpFreeClass(item)" :src="item.equityIntroduceImgUrl" alt="">
            </div>
          </div>
        </div>

      </div>
      <!-- 规则 -->
      <div v-if="selectItem.equityCardItemId" class="bottom-rule">
        <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/month/rule3.png" alt="">
      </div>
      <!-- 吸底 购买按钮 -->
      <div v-if="selectItem.equityCardItemId" class="bottom-btn">
        <div v-if="selectItem.firstPrice && selectItem.payType === 'COMMON'" class="first-price-box f24 row-start-center" :class="{ active: giveUpFirstBuy }" @click="changeGiveUpFirstBuy">自愿放弃新客优惠，使用原价购买。</div>
        <div v-if="selectedCouponId && voucherList.length && selectItem.payType === 'COMMON'" class="select-voucher row-between-center f24" @click="isCouponShow = true">
          <div class="row-start-center" style="white-space: nowrap">
            <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_tips.png" alt="">已减
            <span>{{ selectedDiscount }}元</span>
            <span class="row-start-center">，
              <van-count-down @finish="timeFinish" :time="selectedCouponTips">
                <template #default="timeData">
                  <span style="font-size: 3.2vw;line-height: 4.27vw;white-space: nowrap;">{{ timeData.days }}天{{ timeData.hours }}时{{ timeData.minutes }}分</span>
                </template>
              </van-count-down>
            </span>后失效
          </div>
          <p class="row-start-center"><span>{{ voucherList.length }}张</span><van-icon size="12px" name="arrow" /></p>
        </div>

        <div v-else-if="voucherList.length && selectItem.payType === 'COMMON'" class="select-voucher row-between-center f24" @click="isCouponShow = !(selectItem.firstPrice && !giveUpFirstBuy)">
          <div class="row-start-center"><img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_tips1.png" alt=""><span class="fw6">我的优惠券</span></div>
          <p class="row-start-center" v-if="selectItem.firstPrice && !giveUpFirstBuy">不可与新客优惠同享</p>
          <p class="row-start-center" v-else>{{ voucherList.length }}张<van-icon name="arrow" /></p>
        </div>

        <div v-else-if="selectItem.payType === 'ALI_PRE_ENJOY'" class="auto-renew f22 row-start-center"><van-icon name="info-o" />本次购买的超鹿月卡将立即生效</div>
        <div v-else-if="selectItem.payType === 'AUTO_RENEW'" class="auto-renew f22 row-start-center"><van-icon name="info-o" />本次购买的超鹿月卡将立即生效，扣费日期为权益卡到期当日扣费</div>
        <!-- 勾选协议 -->
        <div v-if="selectItem.payType !== 'ALI_PRE_ENJOY'" class="protocol flex" :class="{ shake: shake }">
          <p @click="protocol = !protocol" :class="{ active: protocol }"></p>
          <div>
            <span @click="protocol = !protocol">点击购买支付即代表您已阅读并同意</span>
            <span class="s1" @click.stop="showProtocol">《超鹿月卡购买服务协议》</span>
            <span v-show="selectItem.payType === 'AUTO_RENEW'">及</span>
            <span v-show="selectItem.payType === 'AUTO_RENEW'" class="s1" @click.stop="showContinuousProtocol">《连续包月协议》</span>
          </div>
        </div>
        <!-- 购买按钮 -->
        <div class="buy-card-btn row-start-center" :class="{ 'send-bg': (selectItem.payType !== 'COMMON' || selectedCouponId || (selectItem.firstPrice && !giveUpFirstBuy)) }">
          <h4 class="f64 fw6"><span class="BebasNeueBold f32">¥&nbsp;&nbsp;</span><span class="BebasNeueBold">{{ salePrice | priceFormat }}</span></h4>
          <div class="flex">
            <p @click="checkAwaitOrder()" class="row-center-center f32 fw6 no-fast-click"></p>
          </div>
        </div>

      </div>
    </app-page>

    <!-- 支付成功不能立即激活弹窗 -->
    <van-popup v-model="isPayUnActivateSuccessShow" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false" @closed="closePaySuccess">
      <div class="pay-box">
        <div class="pay-box-title">
          <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/month-card-2022/group12.png" alt="">
          <div>购买成功！</div>
        </div>
        <div style="margin-top: 17.07vw" class="pay-share-btn row-between-center">
          <div v-if="$route.query.fromOrder === '1'" class="grey-btn row-center-center" @click="dontActivated">返回</div>
          <div class="row-center-center fw6" @click="jumpMyBagCopy">去卡包查看</div>
        </div>
        <div class="pay-box-tips">{{ `会员卡已到账至 “个人中心-卡包”` }}</div>
      </div>
      <img @click="isPayUnActivateSuccessShow = false" class="result-close" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/double11-2021/result-close.png" alt="">
    </van-popup>

    <!-- 支付成功能激活弹窗 -->
    <van-popup v-model="isPaySuccessShow" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false" @closed="closePaySuccess">
      <div class="pay-box big">
        <div class="pay-box-title success">
          <div class="row-center-center">
            <img class="small-right" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/month-card-2022/group12.png" alt="">
            购买成功
          </div>
          <img class="pay-success-tips" src="https://img.chaolu.com.cn/ACT/schedule-2023/pay_success_tips.png" alt="">
        </div>
        <div class="pay-equit-box flex flex-wrap">
          <img v-for="(item, index) in selectItem.equityCardEquityVOList" class="pay-box-item" :src="item.equityIconUrl" :key="index" alt="">
        </div>
        <div class="pay-share-btn row-between-center">
          <div class="row-center-center grey-btn" @click="dontActivated">暂不激活</div>
          <div class="row-center-center fw6" @click="immediateActivated">立即激活生效</div>
        </div>
        <div class="pay-box-tips">会员卡已到账至 “个人中心-卡包”</div>
        <div v-if="appTypeStr !== 'mini'" class="pay-box-tips" style="margin-top: 1.07vw">请升级至最新版APP查看详情</div>
      </div>
    </van-popup>

    <!--人脸提示-->
    <van-popup v-model="showFaceTips" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false" @closed="closePaySuccess">
      <div class="pay-box face-tips">
        <div class="pay-box-title">
          <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/coach/face.png" alt="">
          <div>上课签到规则</div>
          <p>购买超鹿月卡后，使用超鹿月卡预约课程<br>需要在馆课前课后人脸识别打卡签到哦</p>
        </div>
        <div style="margin-top: 8.53vw" class="pay-share-btn row-between-center">
          <div class="row-center-center fw6" @click="checkBuy">我已知晓，继续购买</div>
        </div>
      </div>
      <img @click="showFaceTips = false" class="result-close" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/double11-2021/result-close.png" alt="">
    </van-popup>

    <!--激活成功弹窗-->
    <van-popup v-model="isActivateSuccessShow" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false" @closed="closePaySuccess">
      <div class="pay-box">
        <div class="pay-box-title">
          <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/month-card-2022/group12.png" alt="">
          <div>激活生效成功!</div>
        </div>
        <div style="margin-top: 15vw" class="pay-share-btn row-between-center">
          <div class="row-center-center fw6" @click="immediateUse()">立即使用权益</div>
        </div>
      </div>
      <img @click="isActivateSuccessShow = false" class="result-close" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/double11-2021/result-close.png" alt="">
    </van-popup>

    <!--错误弹窗提示-->
    <van-popup v-model="showError" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false">
      <div class="error-box">
        <h4>提示</h4>
        <p>{{ errorMsg.msg }}</p>
        <div @click="closeError" class="btn row-center-center">我知道了</div>
      </div>
    </van-popup>

    <!--城市确认-->
    <van-popup v-model="isCheckCityShow" :overlay="true" style="background-color: transparent;width: 84vw;" closeable>
      <div class="error-box">
        <h4>提示</h4>
        <p>您当前购买的超鹿月卡，仅限
          <span style="font-weight: bold;color: #ED5C42">{{ cityName }}</span>
          <span v-if="venueName" style="font-weight: bold;color: #ED5C42">【{{ venueName }}】</span>
          <span v-else>所有门店</span>使用，请确认后再付款！
        </p>
        <p v-if="selectItem.payType === 'ALI_PRE_ENJOY'" class="p2">注：本次购买的超鹿月卡将立即生效</p>
        <div @click="checkBuy" class="btn row-center-center">确认</div>
      </div>
    </van-popup>
    <!-- 连续包月提示窗 -->
    <van-popup v-model="isContinuousShow" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false" @closed="closePaySuccess">
      <div class="pay-box big no-bg">
        <img class="auto-img" :src="selectItem.autoRenewPriceImgUrl" alt="">
        <div class="pay-share-btn row-between-center">
          <div class="row-center-center grey-btn" @click="isContinuousShow = false">取消支付</div>
          <div class="row-center-center fw6" @click="checkBuy">开通并购买</div>
        </div>
      </div>
    </van-popup>
    <!-- 已有生效中的卡  却要买连续包月和芝麻先享 的提示 -->
    <van-popup v-model="isHasActivedCardShow" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false" @closed="closePaySuccess">
      <div class="error-box">
        <h4>提示</h4>
        <p v-html="hasActivedTip"></p>
        <div class="pay-share-btn row-between-center">
          <div class="row-center-center grey-btn" @click="isHasActivedCardShow = false">取消</div>
          <div class="row-center-center fw6" @click="checkBuy">继续购买</div>
        </div>
      </div>
    </van-popup>


    <!-- 协议弹窗 -->
    <van-popup v-model="isProtocolShow" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false">
      <div class="protocol-box">
        <h4>“超鹿运动”超鹿月卡服务协议</h4>
        <div class="content">
          {{ monthCardRule }}
        </div>
        <div @click="isProtocolShow = false" class="row-center-center btn">我知道了</div>

      </div>
    </van-popup>
    <!-- 连续包月协议弹窗 -->
    <van-popup v-model="isContinuousProtocolShow" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false">
      <div class="protocol-box">
        <h4>“超鹿运动”连续包月协议</h4>
        <div class="content">
          {{ autoRenewRule }}
        </div>
        <div @click="isContinuousProtocolShow = false" class="row-center-center btn">我知道了</div>
      </div>
    </van-popup>
    <!-- 芝麻活动规则弹窗 -->
    <van-popup v-model="isZhimaShow" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false">
      <div class="protocol-box">
        <h4>活动规则</h4>
        <div class="content">
          {{ currentCityId == '818447834079563776' ? gzZhimaRule : fzZhimaRule }}
        </div>
        <div @click="isZhimaShow = false" class="row-center-center btn">我知道了</div>
      </div>
    </van-popup>
    <!-- 连续包月选择支付方式弹窗 -->
    <van-popup v-model="isRenewPayTypeShow" :safe-area-inset-bottom="true" :overlay="true" position="bottom" :close-on-click-overlay="false" :lock-scroll="true" :closeable="true">
      <div class="renew-paytype-box">
        <div class="pay-type f24 fw6">选择支付方式</div>
        <!--支付宝支付-->
        <!-- <div v-if="appTypeStr !== 'mini'" @click="renewPayType = 2" class="alipay row-between-center" :class="{ 'active': renewPayType === 2 }">
          <div class="row-start-center left"><img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/alipay.png" alt="">支付宝支付
          </div>
        </div> -->
        <div @click="renewPayType = 1" class="wechat row-between-center" :class="{ 'active': renewPayType === 1 }">
          <div class="row-start-center left"><img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/wechatpay.png" alt="">
            微信支付
          </div>
        </div>
        <div @click="buyCard" class="pay-btn row-center-center f32 fw6">确定</div>
      </div>
    </van-popup>
    <!-- 支付弹窗 -->
    <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl" @payComplete="payComplete"></payPopupCard>
    <coupon :dateTips="expireDayTime" :unavailableList="unavailableList" :discount="selectedDiscount" :couponId="selectedCouponId" :list="voucherList" @confirm="confirmVoucher" :showCoupon.sync="isCouponShow"></coupon>
    <!--  分享  -->
    <common-share :value="isShareShow" :shareParams="shareParams" :shareItem="['minifriend']" @close="isShareShow = false" @share-success="isShareShow = false"></common-share>
    <!-- 门店选择 -->
    <equityVenue :current-city-id="currentCityId" :popShow.sync="storeShow" :can-use-venue="selectItem.venues" @popfun="choseStorePop" :chose-item="{
      areaId: '',
      venueId: [currentVenueId],
      lat: local ? local.lat : '',
      lng: local ? local.lng : '',
    }"></equityVenue>
    <!-- 升级提示 -->
    <van-popup v-model="isUpgradeShow" closeable :overlay="true" style="background-color: transparent;width: 84vw;" closeable>
      <div class="error-box">
        <h4>更新提示</h4>
        <p>发现新版本，新功能需要升级至最新版本。</p>
        <div @click="upgrade" class="btn row-center-center">去升级</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import userMixin from '@/mixin/userMixin'
import commonShare from "@/components/commonShare"
import headBar from '@/components/app/headBar'
import appPage from "@/common/components/appPage"
import payPopupCard from "@/components/pay-popup-card"
import coupon from "@/components/pay-popup-card/coupon"
import { autoRenewRule, monthCardRule, fzZhimaRule, gzZhimaRule } from '@/views/month-card/const.js'
import {
  appPaySuccess,
  initBack,
  newAppBack,
  gotoOrderList,
  closeWindow,
  goFreeClassList,
  silentAppPay,
  upgrade,
  getAppVersion
} from "@/lib/appMethod"
import wx from "weixin-js-sdk"
import { getParam, getRedString, compareVersion } from "@/lib/utils"
import { coachProtocol } from "@/views/month-card/const"
import equityVenue from "@/components/cl-city-venue/equity-venue.vue"

export default {
  data() {
    return {
      autoRenewRule,
      monthCardRule,
      gzZhimaRule,
      fzZhimaRule,

      isShareShow: false,
      giveUpFirstBuy: false,
      shareParams: {
        title: '购买超鹿月卡，享超值福利。',
        miniImage: 'https://img.chaolu.com.cn/ACT/sell-membership-2022/month/share.png',
        path: '/pages/webView/index?webUrl=' + window.location.origin + '/#/superdeer-activity/sell-membership',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
        url: 'url',
        multiple: "0.95",
        userName: "gh_7f5b57b6a76e",
      },

      isCouponShow: false,
      isPaySuccessShow: false,
      isContinuousShow: false, // 连续包月的提示弹窗
      isHasActivedCardShow: false, // 已有生效中的卡
      hasActivedTip: '', //
      isPayUnActivateSuccessShow: false,
      isActivateSuccessShow: false,
      isCheckCityShow: false, // 城市确认弹窗
      isUpgradeShow: false, // app升级提示
      isZhimaShow: false, // 芝麻先享活动规则

      isRenewPayTypeShow: false, // app签约的支付方式弹窗
      renewPayType: 1,

      checkedCityName: '', // 弹出城市确认弹窗的城市

      selectItem: {}, // 当前选择卡下标
      cardInfo: {
        items: []
      },

      protocol: false, // 勾选用户协议
      isProtocolShow: false, // 勾选用户协议
      isContinuousProtocolShow: false,

      showError: false,
      shake: false,
      errorMsg: {},
      finished: false, // 接口是否已经加载完成

      showPayPopup: false,
      showFaceTips: false,
      payData: {},
      payUrl: '',
      voucherList: [],
      unavailableList: [], //不可用列表
      selectedDiscount: 0,
      selectedCouponId: '',
      selectedCouponTips: '', // 失效提示
      expireDayTime: '', // 失效提示

      ticketSource: 'WEB', // 购买来源 WEB 首页主动进入  SMS 短信进入
      currentCityId: '3', // 默认福州
      currentVenueId: '', // 选择生效门店
      cityName: '福州市',
      venueName: '',
      distance: '',

      headerHeight: 0,
      cityList: [],
      storeShow: false,

      choseItem: {},
      channelCode: '',  // 购买渠道标识

      isExpand: true, // 卡列表 展开收起
    }
  },
  mixins: [userMixin],
  components: {
    equityVenue,
    appPage,
    headBar,
    payPopupCard,
    coupon,
    commonShare
  },
  filters: {
    priceFormat(v) {
      if (!v) {
        return 0
      }
      return parseFloat(v)
    }
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.isShareShow = false
      } else if (document.visibilityState === "hidden") {
        this.isShareShow = false
        this.isPaySuccessShow = false
        this.isPayUnActivateSuccessShow = false
        this.isActivateSuccessShow = false
      }
    })
    if (this.appTypeStr !== 'mini') {
      setTimeout(() => {
        this.headerHeight = this.$refs.headerHeight.$el.clientHeight - 1 || 0
      }, 1500)
    }
  },
  watch: {
    selectItem(value) {
      this.currentVenueId = ''
      this.venueName = ''
      this.distance = ''
    }
  },
  computed: {
    salePrice() {
      if (!this.giveUpFirstBuy && this.selectItem.firstPrice) {
        return this.selectItem.firstPrice
      }
      // 不选券使用限时售卖价
      if (!this.selectedDiscount || !this.selectedCouponId) {
        console.log(this.selectItem.salePrice)
        return this.selectItem.salePrice
      }
      // 最终售价
      let p = this.selectItem.salePrice || 0
      let voucherAfter = this.selectedDiscount || 0
      let price = (p - voucherAfter) < 0 ? 0 : (p - voucherAfter)
      return price.toFixed(2)
    }
  },
  async created() {
    initBack();
    await this.$getAllInfo(['userId', 'cityId', 'local'])
    this.currentCityId = await this.$getCityId() || '3'
    if (this.$route.query.venueId) {
      this.currentVenueId = this.$route.query.venueId
      let r = await this.getVenueInfo()
      if (r.cityId) {
        this.currentCityId = r.cityId
        this.cityName = r.cityName
      }
    }
    this.currentCityId = this.$route.query.cardCityId || this.currentCityId
    if (this.$route.query.ticketSource) {
      this.ticketSource = this.$route.query.ticketSource
    }
    this.getCityList()
    this.getCardList(true)
    if (this.appTypeStr === 'mini') {
      appPaySuccess().then(res => {
        // this.checkCanActivated()
        this.isPayUnActivateSuccessShow = true
      })
    }
    this.clickId = this.$route.query.clickId
    this.addUserAction('CONFIRM_EFFECTIVE_LEADS')

    const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/sell-membership`)
    this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
    wx.miniProgram.postMessage({
      data: {
        type: 'share',
        shareImage: this.shareParams.miniImage,
        shareTitle: this.shareParams.title,
        shareUrl: this.shareParams.path,
      },
    })
    if (this.$route.query.channelCode) {
      this.channelCode = this.$route.query.channelCode
      this.$axios.post(this.baseURLApp + '/dataPoint/dataPoint', {
        userId: this.userId || 0,
        activityType: 401,
        activityId: 401,
        templateId: 40002,
        count: "0",
        amount: "0",
        ext1: this.$route.query.channelCode
      }).then((res) => {
        console.log('埋点成功', this.$route.query.channelCode)
      })
    }
  },
  methods: {
    newAppBack,
    upgrade,
    closeError() {
      this.showError = false
      if (this.errorMsg.skipOrder) {
        gotoOrderList()
      }
    },
    confirmVoucher(item) {
      if (item) {
        this.giveUpFirstBuy = true
        this.selectedDiscount = item.discount || 0
        this.expireDayTime = item.expireDayTime || 0
        this.selectedCouponId = item.id || ''
        let time = Math.round(item.expireDayTime * 1000 - (new Date().getTime())) || 0
        this.selectedCouponTips = time < 0 ? 0 : time
      }
    },
    changeGiveUpFirstBuy() {
      this.giveUpFirstBuy = !this.giveUpFirstBuy
      if (!this.giveUpFirstBuy) {
        this.selectedDiscount = 0
        this.selectedCouponId = ''
        this.selectedCouponTips = 0
        this.expireDayTime = 0
      }
    },
    payComplete(v) {

    },
    // 获取我的优惠券最高金额  及 可用和不可用列表
    getVoucherList(cardItemId) {
      this.$axios.post(`${this.baseURLApp}/voucher/equityCardVoucher`, {
        userId: this.userId,
        token: this.token,
        cardItemIds: [cardItemId],
      }, false).then(res => {
        let data = res.data.list ? res.data.list[0] : {}
          this.voucherList = data.availableList || []  // 可用列表
          this.unavailableList = data?.unavailableList || [] // 不可用列表
          // 如果有首约价就不选择优惠券
          if (!this.giveUpFirstBuy && this.selectItem.firstPrice) {
            this.selectedDiscount = 0
            this.selectedCouponTips = 0
            this.expireDayTime = 0
            this.selectedCouponId = ''
            return false
          }
          this.selectedDiscount = data?.maxDiscount || 0
          this.expireDayTime = data?.expireDayTime || 0
          this.selectedCouponId = data?.maxDiscountVoucherId || ''
          let time = Math.round(data?.expireDayTime * 1000 - (new Date().getTime())) || 0
          this.selectedCouponTips = time < 0 ? 0 : time
      })
    },
    // 倒计时结束刷新优惠券
    timeFinish() {
      // this.getVoucherList(this.selectItem.equityCardItemId) // 请求用户的优惠券
    },
    getVenueInfo() {
      return new Promise(resolve => {
        this.$axios.post(`${this.baseURLApp}/newVenue/getVenueInfo`, {
          userId: this.userId,
          token: this.token,
          venueId: this.currentVenueId
        }).then(res => {
          resolve({ cityId: res.data.cityId, cityName: res.data.cityName })
        }).catch(() => {
          resolve({ cityId: '3', cityName: "福州市" })
        })
      })
    },
    getCardList(isFirst) {
      // this.$toast.loading({
      //   forbidClick: true,
      //   loadingType: 'spinner',
      //   duration: 0,
      //   message: '加载中...'
      // });
      this.$axios.post(`${this.baseURLApp}/equityCard/equityCardChaoluMall`, {
        userId: this.userId,
        token: this.token,
        cityId: this.currentCityId,
        lat: this.local ? this.local.lat : '',
        lng: this.local ? this.local.lng : '',
      }).then(res => {
        this.finished = true
        if (res?.data) {
          this.cardInfo = res?.data || {}
          let cardList = this.cardInfo?.items?.length ? this.cardInfo.items : []
          // 选中指定天数卡类型
          if (this.$route.query.cardDayType) {
            let item = cardList.find(v => v.useValidity == this.$route.query.cardDayType)
            console.log(item)
            this.selectItem = item ? item : cardList[0]
          } else {
            this.selectItem = cardList[0]
          }

          this.getVoucherList(this.selectItem.equityCardItemId) // 请求用户的优惠券
        } else {
          this.selectItem = {}
          this.cardInfo = {}
        }
        // this.$toast.clear()
        this.$forceUpdate()
      }).catch(() => {
        this.finished = true
      })
    },
    async selectCard(v) {
      this.selectItem = v
      this.giveUpFirstBuy = false
      await this.getVoucherList(v.equityCardItemId) // 请求用户的优惠券
    },

    // 校验是否有使用优惠券的待支付订单
    async checkAwaitOrder() {
      let version = await getAppVersion()
      if (this.selectItem.payType !== 'COMMON' && compareVersion(version, '4.34.10') < 0) {
        this.isUpgradeShow = true
        return
      }

      if (!this.protocol && this.selectItem.payType !== 'ALI_PRE_ENJOY') {
        this.shake = true
        this.$toast({
          message: '请仔细阅读并勾选购买协议！',
          forbidClick: true,
          onClose: () => {
            this.shake = false
          }
        })
        return
      }

      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      this.$axios.post(`${this.baseURLApp}/order/equityCardUser/monthlyCardToBePaidCouponVerification`, {
        userId: this.userId,
        userToken: this.token,
      }, false).then(res => {
        this.showChooseVenue()
        this.$toast.clear()
      }).catch((r) => {
        this.errorMsg = { msg: r.msg }
        this.showError = true
        this.$toast.clear()
      })
    },
    // 校验是否阅读完月卡协议
    showProtocol() {
      this.isProtocolShow = true
    },
    showContinuousProtocol() {
      this.isContinuousProtocolShow = true
    },
    // 提示购买城市
    checkCity() {
      console.log(this.checkedCityName)
      // if (this.checkedCityName && this.checkedCityName === this.cityName) {
      //   this.checkBuy()
      //   return false
      // }

      if (this.selectItem.payType === 'AUTO_RENEW') {
        this.isContinuousShow = true
      } else {
        this.isCheckCityShow = true
        this.checkedCityName = this.cityName
      }
    },
    // 校验是否可以买月卡
    checkBuy() {
      this.isCheckCityShow = false

      // 人脸提示 暂时隐藏    线下未配置人脸识别设备前，暂时不提示弹窗;
      // if(!this.showFaceTipsed) {
      //     this.showFaceTips = true
      //     this.showFaceTipsed = true
      //     return false
      // }

      // 芝麻先享 或 连续包月的 要检测是否有正在使用的卡
      if (this.selectItem.payType !== 'COMMON') {
        let param = {
          equityCardItemId: this.selectItem.equityCardItemId,
          userId: this.userId,
          token: this.token,
        }
        this.$axios.post(`${this.baseURLApp}/equityCard/check/autoRenueBuyRemind`, param, false).then(res => {

          // 有提示 说明有正在使用的卡
          if (res.data.remind && !this.isHasActivedCardShow) {
            // 如果是芝麻先享 不能买
            // if (this.selectItem.payType === 'ALI_PRE_ENJOY') {
            //   this.$toast('您已存在自动续费的权益卡，无法再次开通')
            //   return
            // }
            // 替换文字颜色 展示弹窗
            this.hasActivedTip = res.data.remind.content
            res.data.remind.keys.map((r) => {
              this.hasActivedTip = getRedString(this.hasActivedTip, r)
            })
            this.isHasActivedCardShow = true
            return
          }

          if (this.appTypeStr !== 'mini' && this.selectItem.payType !== 'ALI_PRE_ENJOY') {
            this.close()
            this.isRenewPayTypeShow = true
          } else {
            this.buyCard()
          }

        })
      } else {
        let param = {
          equityCardItemId: this.selectItem.equityCardItemId,
          userId: this.userId,
          token: this.token,
          perVenueId: this.currentVenueId,
          source: 'SELF_BUY',
          giveUpFirstBuy: this.giveUpFirstBuy
        }
        this.$axios.post(`${this.baseURLApp}/equityCard/check/buy`, param, false).then(res => {
          if (res.data) {
            this.buyCard()
          }
        }).catch((r) => {
          this.errorMsg = {
            msg: r.msg,
            skipOrder: r?.data?.skip === 'ORDER_CENTER'
          }
          this.showError = true
        })
      }
    },
    closePaySuccess() {
      // 关闭支付成功
    },
    // 购买
    buyCard() {
      // 点击购买页 上报
      this.addUserAction('COMPLETE_ORDER')
      let info = this.selectItem
      let paramUrl = getParam().url
      localStorage.setItem('paramUrl', paramUrl)
      let url = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership&userId=1&cityId=1&venueId=${this.$route.query.venueId || '31'}&cardCityId=${this.currentCityId}&fromOrder=${paramUrl ? '1' : ''}`
      let payData = {
        subject: `${info.equityCardItemName}（${info.useValidity}天）`,
        extendsJson: {
          appVersion: '3.61.10',
          seriesName: info.equityCardItemName,
          ticketSource: this.ticketSource,
          discountId: this.selectedCouponId,
          giveUpFirstBuy: this.giveUpFirstBuy,
          perUseVenueId: this.currentVenueId || '',
          sourceChannel: this.channelCode || '',
        },
        orderEquityCardCreateReq: {
          source: 'SELF_BUY'
        },
        totalAmount: (this.giveUpFirstBuy || !info.firstPrice) ? info.salePrice : info.firstPrice,
        firstPrice: info.firstPrice,
        venueId: this.currentVenueId || '31',
        goodsNum: "1",
        isApp: '1',
        type: '25',
        selectedDiscount: this.selectedDiscount,
        discountId: this.selectedCouponId,
        userId: this.userId,
        token: this.token,
        cityId: this.currentCityId,
        extendsType: '4',
        mouldId: info.equityCardItemId,
        whetherPrivacy: 0,
        originalPriceStr: info.originalPrice,
        title: '购买内容',
        useTips: `全${this.cityName}可用`,
      }
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      }
      this.payData = payData
      this.payUrl = url

      switch (this.selectItem.payType) {
        case 'ALI_PRE_ENJOY': // 芝麻先享
          payData.tradeType = 'SUBSCRIPTION'
          if (this.appTypeStr === 'mini') { // 小程序环境 跳转待支付页 复制芝麻口令
            const redirectUri = encodeURIComponent(url)
            wx.miniProgram.navigateTo({
              url: `/pages/webViewPay/index?payData=${JSON.stringify(payData)}&redirect_uri=${redirectUri}`,
            })
          } else {
            silentAppPay(2, payData, url)
          }
          this.close()
          break;
        case 'AUTO_RENEW': // app周期付
          payData.tradeType = this.appTypeStr === 'mini' ? 'APPLET_WITH_CYCLE' : 'APP_WITH_CYCLE'
          silentAppPay(this.renewPayType, payData, url)
          this.close()
          break;
        default: // 常规流程
          this.showPayPopup = true
          break;
      }

      if (this.appTypeStr !== 'mini') {
        appPaySuccess().then(res => {
          this.isPayUnActivateSuccessShow = true
          this.getCardList();
        })
      }
    },
    jumpMyBagCopy() {
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/month-card/my-card&userId=1&token=1`
        })
      } else {
        this.$router.push({
          path: '/month-card/my-card',
        })
      }
    },
    // 判断是否能立即激活，暂时移除
    // checkCanActivated() {
    //   this.$axios.post(`${this.baseURLApp}/user/equityCard/isActiveEquityCardUser`, {
    //     type: 2,
    //     userId: this.userId,
    //     token: this.token,
    //   }).then(res => {
    //     this.equityCardUserId = res.data.equityCardUserId
    //     this.equityCardUserId ? this.isPaySuccessShow = true : this.isPayUnActivateSuccessShow = true
    //   })
    // },
    // 立即激活
    immediateActivated() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      this.$axios.post(`${this.baseURLApp}/user/equityCard/open`, {
        userId: this.userId,
        token: this.token,
        // userEquityCardId: this.equityCardUserId
      }).then((res) => {
        this.$toast.clear()
        this.isPaySuccessShow = false
        this.isActivateSuccessShow = true
      }).catch(() => {
        this.$toast.clear()
      })
    },
    // 立即使用
    immediateUse() {
      if (getParam().fromOrder === '1' || getParam().equityCardItemId) {
        this.jumpMyOrder()
      } else {
        this.jumpMyBagCopy()
      }
    },
    // 返回订单页
    dontActivated() {
      if (getParam().fromOrder === '1' || getParam().paramUrl) {
        this.jumpMyOrder()
      } else {
        this.isPaySuccessShow = false
        this.isPayUnActivateSuccessShow = false
      }
    },
    // 跳转订单页面
    jumpMyOrder() {
      console.log('触发返回')
      if (this.appTypeStr === 'mini') {
        const url = localStorage.getItem('paramUrl')
        wx.miniProgram.redirectTo({
          url: decodeURIComponent(url)
        })
      } else {
        closeWindow()
      }
    },
    /**
     * 小程序广告转化
     * @param actionType 预定义的行为类型，目前只支持COMPLETE_ORDER（下单）及RESERVATION（表单预约）、CONFIRM_EFFECTIVE_LEADS（有效销售线索）
     */
    addUserAction(actionType) {
      if (!this.clickId) {
        return false
      }
      this.$axios.post(`${this.baseURLApp}/chaolu-wx-applet/action/addUserAction/v1`, {
        actionUrl: 'superdeer-activity/sell-membership',
        clickId: this.clickId,
        actionType: actionType
      }, false).then(res => {
        console.log('广告数据转化埋点：类型', actionType, '返回参数', res)
      }).catch(r => {
        console.log('广告数据转化埋点：类型', actionType, '埋点接口调用失败')
      })
    },

    getCityList() {
      this.$axios.post(`${this.baseURLApp}/scheduleLesson/cityList`, { userId: this.userId }).then(res => {
        this.cityList = res.data
        let item = this.cityList.find(r => { return r.cityId === this.currentCityId })
        if (item) {
          this.cityName = item.areaName
          this.currentCityId = item.cityId
        }
      })
    },
    cityChange(v) {
      this.cityName = v.areaName
      this.currentCityId = v.cityId
      this.giveUpFirstBuy = false
      this.checkedCityName = ''
      this.getCardList()
      // 修改分享参数
      const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/sell-membership`)
      this.shareParams.path = `/pages/webView/index?cardCityId=${this.currentCityId}&webUrl=${uu}`
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    },

    showChooseVenue(isChange = false) {
      this.manualChange = isChange
      if (isChange || this.selectItem?.isPerOnlyVenue && !this.currentVenueId) {
        this.checkedCityName = ''
        this.storeShow = true
      } else {
        this.checkCity()
      }
    },
    //门店弹出窗口返回的数据
    choseStorePop(val, param, choseName) {
      this.storeShow = false
      this.storeTotal = choseName || this.storeTotal
      if (param) {
        let { venueId, venueName, areaId } = param
        this.choseItem.venueId = venueId || []
        this.choseItem.areaId = areaId
        this.currentVenueId = venueId[0]
        this.venueName = venueName
        this.distance = (this.selectItem.venues || []).find(r => r.venueId == this.currentVenueId)?.distance
        if (this.currentVenueId && !this.manualChange) {
          this.checkBuy()
        }
      }
    },
    // 权益项3 跳转免费团课
    jumpFreeClass(item) {
      if (item.skip === 'FREE_SCHEDULE_COURSE') {
        goFreeClassList()
      }
    },
    close() {
      this.isHasActivedCardShow = false
      this.isContinuousShow = false
      this.isRenewPayTypeShow = false
    }
  },
};
</script>
<style lang="less" scoped>
.share-icon {
  width: 38px;
}

.new-card-list {
  margin: 32px 0 0;
  padding: 0 32px;
  height: 250px;
  overflow: hidden;

  &.expand {
    height: auto;
  }

  .card-item {
    width: 20%;
    max-width: 160px;
    min-height: 230px;
    box-sizing: border-box;
    background: #F9FAFB;
    border-radius: 16px;
    border: 2px solid #E5E5E5;
    margin: 0 14px 23px 0;
    position: relative;
    padding: 44px 0 16px;

    &:nth-child(4n) {
      margin: 0 0 23px 0;
    }

    &.active {
      background-color: #FFF9E3;
      border-color: #DA970F;

      .couponAfterPrice {
        background: linear-gradient(90deg, #FFF100 0%, #FFDE00 100%);
      }

      .item-tips {
        color: #A56309;
      }
    }

    .card-name {
      width: 100%;
      text-align: center;
    }

    .item-tips {
      color: #6C727A;
      width: 90%;
      text-align: center;
      margin: 10px 0 0;
    }

    .price {
      color: #F03C18;
      white-space: nowrap;
      margin: 14px 0 0;
    }

    .ori-price {
      text-decoration: line-through;
      color: #6C727A;
      margin: 12px 0 0;
    }

    .couponAfterPrice {
      height: 36px;
      background-color: #ECC589;
      border-radius: 4px;
      color: #A56309;
      font-size: 22px;
      font-weight: bold;
      padding: 0 4px;
      //margin-bottom: 12px;
      white-space: nowrap;
    }

    .tag {
      position: absolute;
      left: 0;
      top: 0;
      padding: 0 12px;
      height: 32px;
      background-color: #FF6E00;
      border-radius: 15px 0px 15px 0px;
      color: #fff;

      &.blue {
        background-color: #2B71F1;
      }
    }
  }
}

.expand-button {
  margin: 22px 0 24px;
  color: #6C727A;

  .van-icon {
    margin: 0 0 0 10px;
    transform: rotateZ(90deg);
    transition: all .2s;

    &.rotate {
      transform: rotateZ(-90deg);
    }
  }
}

.bottom-rule {
  padding: 24px 32px 62px;
  background: #F5F5F5;

  img {
    display: block;
    width: 100%;
  }
}

.page-box {
  min-height: 100vh;
  // padding-bottom: 280px;
  background-color: #20252E;

  &>div {
    width: 750px;
  }

  .page-h {
    position: relative;
    width: 750px;
    overflow: hidden;

    padding: 4px 0 60px;
    background-color: #20252E;

    &>div {
      width: 686px;
      height: 320px;
      border-radius: 16px;
      background: #fff no-repeat center center;
      background-size: 100% 100%;
      padding-bottom: 24px;
      box-sizing: border-box;

      &>p {
        padding: 24px 0 0 28px;
        font-size: 44px;
        line-height: 52px;
        color: white;
        font-family: PuHuiTi;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        -webkit-background-clip: text;

        span:first-child {
          font-size: 52px;
          line-height: 61px;
          margin-bottom: 18px;
        }
      }

      &>div {
        color: #FFFFFF;
        padding: 28px 28px 0;

        .level-describe {

          p {
            padding: 10px 12px;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 4px;
            font-size: 18px;
            line-height: 20px;
            margin: 0 12px 0 0;
          }
        }

        .select-venue-btn {
          border-radius: 8px;
          background: rgba(0, 0, 0, 0.26);
          height: 50px;
          line-height: 18px;
          font-size: 18px;

          div {
            font-size: 22px;
            font-weight: bold;
            color: #242831;
            border-radius: 8px;
            background: #FFFFFF;
            height: 50px;
            padding: 0 20px;
          }

          span {
            margin-right: 12px;
            padding-left: 12px;
          }
        }
      }
    }
  }

  .card-box {
    background-color: white;
    padding: 32px 0 0;
    border-radius: 30px 30px 0 0;
    margin: -20px 0 0;
    position: relative;

    .card-title {
      padding: 0 32px;

      .p1 {
        color: #6C727A;
      }
    }

    .card-tips {
      color: #3C454E;
      padding: 0 32px;
      margin: 36px 0 24px;
      text-align: justify;
      word-break: break-all;
    }

    .gift-detail {
      background: #F5F5F5;
      padding: 36px 32px;

      .gift-detail-bottom {

        h4 {
          font-size: 32px;
          color: #232831;

          span {
            font-weight: 400;
            line-height: 22px;
            color: #3C454E;
            font-size: 22px;
          }
        }

        &>p {
          font-size: 24px;
          color: #3C454E;
          line-height: 24px;
          margin-top: 20px;
        }

        .gift-detail-item {
          width: 686px;
          border-radius: 16px;
          margin-top: 28px;
          position: relative;

          img {
            flex: none;
            width: 100%;
            display: block;
          }
        }
      }
    }
  }

  .bottom-btn {
    background-color: white;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 750px;
    padding: 12px 0 calc(12px + env(safe-area-inset-bottom));
    z-index: 999;

    .first-price-box {
      width: 750px;
      height: 64px;
      color: #267DFF;
      padding-left: 72px;
      box-sizing: border-box;
      background: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/price_select.png") no-repeat #ECF3FE 28px center;
      background-size: 40px 40px;

      &.active {
        background-image: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/price_selected.png");
      }
    }

    .select-voucher {
      width: 100vw;
      height: 72px;
      background: #FDEEEC;
      margin: 0 auto 0;
      padding: 0 32px;
      box-sizing: border-box;

      img {
        height: 32px;
        margin-right: 12px;
      }

      &>div {
        span {
          color: #F03C18;
        }
      }

      &>p {
        span {
          margin-left: 4px;
        }
      }
    }
    .auto-renew {
      color: #FF6E00;
      padding: 8px 32px 0;
      .van-icon {
        margin: 0 4px 0 0;
      }
    }
    .buy-card-btn {
      width: 686px;
      height: 112px;
      margin: 12px auto 32px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      position: relative;
      border-radius: 24px;
      background-image: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/month/btn.png");

      &.send-bg {
        background-image: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/month/btn1.png");
      }

      &>h4 {
        color: #F7DFB4;
        width: 360px;
        padding-left: 80px;
        box-sizing: border-box;
        position: relative;
        top: 2px;
        line-height: 1;
      }

      &>div {
        p {
          width: 314px;
          height: 112px;
          border-radius: 22px;
          color: #242831;
        }
      }
    }

    .protocol {
      padding: 12px 28px 0;

      &.shake {
        animation: shake 1s;
      }

      p {
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        background: url("https://img.chaolu.com.cn/ACT/invite-2022/icon7.png") no-repeat center center;
        background-size: 100% 100%;

        &.active {
          background-image: url("https://img.chaolu.com.cn/ACT/invite-2022/icon6.png");
        }
      }

      div {
        font-size: 18px;
        line-height: 26px;
        color: #707072;
        padding-top: 5px;

        .s1 {
          font-weight: bold;
          color: #232831;
        }
      }
    }

  }
}

.protocol-box {
  width: 630px;
  height: 900px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0 auto;

  h4 {
    font-size: 36px;
    font-weight: 600;
    color: #242831;
    line-height: 36px;
    text-align: center;
    padding-top: 58px;
  }

  .content {
    &::-webkit-scrollbar {
      display: none;
    }

    height: 570px;
    overflow-x: auto;
    margin: 32px auto 56px;
    width: 630px;
    padding: 10px 32px;
    font-size: 24px;
    font-weight: 400;
    color: #3C454E;
    line-height: 44px;
    white-space: pre-wrap;
    box-sizing: border-box;
    text-align: justify;

    p {
      text-indent: 2em;
      margin-bottom: 16px;
    }

    .bold {
      font-weight: bold;
    }

    .red {
      color: red;
    }
  }


  .btn {
    margin: 0 auto;
    font-weight: bold;
    width: 566px;
    height: 96px;
    background: #FFDE00;
    border-radius: 8px;
    font-size: 32px;
    color: #242831;
    line-height: 32px;

    &.gray {
      background: #9AA1A9;
    }
  }
}

.error-box {
  width: 630px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0 auto;
  padding-bottom: 48px;

  &>span {
    display: block;
    text-align: center;
    margin-bottom: 24px;
    font-size: 28px;
    font-weight: bold;
    color: #0a7aff;
  }

  h4 {
    font-size: 36px;
    font-weight: 600;
    color: #242831;
    line-height: 36px;
    text-align: center;
    padding-top: 58px;
  }

  p {
    margin: 32px auto 56px;
    width: 510px;
    font-size: 28px;
    font-weight: 400;
    color: #3C454E;
    line-height: 44px;
  }
  .p2 {
    color: #FF6E00;
    margin: 0 auto 12px;
  }
  .btn {
    margin: 0 auto;
    font-weight: bold;
    width: 510px;
    height: 96px;
    background: #FFDE00;
    border-radius: 8px;
    font-size: 32px;
    color: #242831;
    line-height: 32px;
  }
}

// 支付成功弹窗
.pay-box {
  width: 590px;
  box-sizing: border-box;
  margin: 0 auto;
  background: white url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/month-card-2022/buy-success-bg.png") no-repeat center top;
  background-size: 100% 554px;
  border-radius: 16px;
  overflow: hidden;
  padding-bottom: 40px;

  &.big {
    width: 622px;
  }

  &.no-bg {
    background: white;

    img {
      width: 100%;
    }
    .auto-img {
      padding: 40px 32px 0;
      box-sizing: border-box;
    }
  }

  &.face-tips {
    background: white;

    .pay-box-title {
      font-size: 36px;

      img {
        padding: 0 0 32px;
        width: 400px;
        height: 400px;
      }

      p {
        font-size: 26px;
        color: #242831;
        line-height: 44px;
        width: 500px;
        text-align: center;
        margin: 24px auto 0;

      }
    }
  }

  .pay-box-title {
    &.success {
      padding: 64px 0 0;
    }

    .pay-success-tips {
      width: 444px;
      height: 28px;
      display: block;
      margin: 0 auto;
      padding: 48px 0 0;
    }

    .small-right {
      width: 64px;
      height: 64px;
      padding: 0;
      margin: 0 16px 0 0;
    }

    img {
      width: 128px;
      height: 128px;
      display: block;
      margin: 0 auto;
      padding: 64px 0 48px;
    }

    div {
      font-weight: bold;
      color: #242831;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
    }
  }

  .pay-box-text {
    font-size: 28px;
    color: #242831;
    line-height: 40px;
    margin-top: 24px;
    text-align: center;
  }

  .pay-box-tips {
    font-size: 22px;
    color: #6C727A;
    line-height: 26px;
    text-align: center;
    width: 460px;
    margin: 40px auto 0;

    .s1 {
      color: #FF6E00;
      font-size: 22px;
    }
  }

}
.pay-share-btn {
    div {
      width: 100%;
      height: 96px;
      border-radius: 8px;
      border: 1px solid #FFDE00;
      box-sizing: border-box;
      background: #FFDE00;
    }

    .grey-btn {
      width: 206px;
      flex: none;
      margin: 0 24px 0 0;
      background-color: #fff;
      border: 1px solid #CCCCCC;
    }

    padding: 0 32px;
    height: 96px;
    margin: 48px auto 0;
    color: #242831;

  }
.renew-paytype-box {
  padding: 0 32px 32px;
  box-sizing: border-box;

  .pay-btn {
    width: 686px;
    height: 96px;
    background: #FFDE00;
    border-radius: 8px;
    color: #242831;
    margin: 72px 0 38px;
  }

  .pay-type {
    color: #242831;
    padding: 32px 0 60px;
  }

  .wechat,
  .alipay {
    background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/normal.png") no-repeat center right;
    background-size: 40px 40px;
    padding: 24px 0;

    &.active {
      background-image: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/active.png");
    }

    img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }
  }
}

.result-close {
  width: 60px;
  height: 60px;
  margin: 48px auto 0;
  display: block;
}

.city-nav-box {
  padding: 32px;
  width: 100vw;
  box-sizing: border-box;
  overflow-x: auto;
  background-color: #20252E;

  div {
    //width: 148px;
    flex-shrink: 0;
    height: 56px;
    background: #181D25;
    border-radius: 8px;
    color: #fff;
    font-size: 22px;
    padding: 0 24px;
    border: 1px solid #686C75;
    margin-right: 24px;
    box-sizing: border-box;

    &.active {
      font-weight: bold;
      color: #242831;
      border-color: #fff;
      background: #FFFFFF;
    }
  }
}

/deep/ .red {
  color: #FF6E00;
}

.pay-equit-box {
  margin: 40px 0 0;
  padding: 0 0 0 34px;

  .pay-box-item {
    width: 123px;
    height: 128px;
    margin: 0 22px 20px 0;
  }
}
</style>
